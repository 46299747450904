/**
 * imageOptions: Pass in the array of images [{imageUrl: string, imageAlt: string}] in the order that they should be displayed
 *
 * metaUrl: Get the href from the request and pass that in
 *
 * metaTitle: Enter in the preferred title
 *
 * metaDescription: Enter in the preferred description
 */
export function generateMetaFunctionData({
  imageOptions,
  metaUrl,
  metaTitle,
  metaDescription,
}: {
  imageOptions: {
    imageUrl: string;
    imageAlt: string;
  }[];
  metaTitle: string;
  metaDescription: string;
  metaUrl: string;
}) {
  function getTargetImage(
    imageOptions: { imageUrl: string; imageAlt: string }[]
  ) {
    for (const option of imageOptions) {
      if (option.imageUrl && option.imageAlt) {
        return {
          imageUrl: option.imageUrl,
          imageAlt: option.imageAlt,
        };
      }
    }

    return {
      imageUrl: "",
      imageAlt: "",
    };
  }

  let { imageUrl, imageAlt } = getTargetImage(imageOptions);

  // console.log({ imageUrl });

  let imageDimensionRegex = /[0-9]{3,4}x[0-9]{3,4}/gi;

  let imageDimensions = imageDimensionRegex.exec(imageUrl) || ["", ""];

  var dimensions = imageDimensions[0].split("x");

  // console.log({ dimensions });

  var metaFunctionData = {
    imageAlt: imageAlt,
    imageUrl: imageUrl,
    metaUrl,
    metaTitle,
    metaDescription,
    imageWidth: dimensions[0],
    imageHeight: dimensions[1],
  };

  // console.log(metaFunctionData);
  return metaFunctionData;
}

/**
 * Pass the meta data that was generated in the loader to this function and then return the object it generates
 *
 * Usage:
 * export let meta: MetaFunction = ({ data }) => {
 * let metaData: MetaData = data.metaFunctionData;
 * let metaFunctionData = useMetaFunctionData(metaData);
 * return metaFunctionData;
 * };
 */
export function useMetaFunctionData(metaFunctionData: MetaFunctionData) {
  let metaTitle = metaFunctionData?.metaTitle || "";
  let metaDescription = metaFunctionData?.metaDescription || "";
  let metaUrl = metaFunctionData?.metaUrl || "";
  let imageAlt = metaFunctionData?.imageAlt || "";
  let imageUrl = metaFunctionData?.imageUrl || "";
  let imageHeight = metaFunctionData?.imageHeight || "";
  let imageWidth = metaFunctionData?.imageWidth || "";

  return {
    title: metaTitle,
    description: metaDescription,
    "og:type": "website",
    "og:url": metaUrl,
    "og:title": metaTitle,
    "og:description": metaDescription,
    "og:image": imageUrl,
    "og:image:width": imageWidth,
    "og:image:height": imageHeight,
    "og:image:alt": imageAlt,
    "og:site_name": "Hey FSJ Businesses",
    "twitter:card": "summary_large_image",
    "twitter:image": `${imageUrl}?w=2160&q=40&auto=format&dpr=3`,
    "twitter:image:alt": imageAlt,
    "twitter:title": metaTitle,
    "twitter:creator": "@HeyFsj",
    "twitter:description": metaDescription,
  };
}

type MetaFunctionData = {
  imageAlt: string;
  imageUrl: string;
  metaUrl: string;
  metaTitle: string;
  metaDescription: string;
  imageWidth: string;
  imageHeight: string;
};
